import { LaborOrder } from "src/app/core/interfaces/labor-order.interface";
import { TimeSheet } from "../../../../core/interfaces/timesheet.interface";
import { TimeSheetRow } from "../../interfaces/timesheet-row.model";
import { UpdateTimeSheetRowStatus } from "../../interfaces/update.timesheetrow.status.model";

export class LoadTimeSheets {
  public static readonly type = "[TimeSheets] load";
  constructor(public skipCount: number, public maxResult: number, public filters?: any) {}
}

export class GetTimeSheetById {
  public static readonly type = "[TimeSheets] get by ID";
  constructor(public id: string) {}
}

export class ClearTimeSheet {
  public static readonly type = "[TimeSheets] clear";
}

export class AddTimeSheet {
  public static readonly type = "[TimeSheets] add";
  constructor(public payload: TimeSheet, public dialogCallback: () => void) {}
}

export class UpdateTimeSheet {
  public static readonly type = "[TimeSheets] update";
  constructor(public payload: TimeSheet, public callback: (error) => void) {}
}

export class RowRemoved {
  public static readonly type = "[TimeSheets] row removed";
}

export class RowAddedOrRemoved {
  public static readonly type = "[TimeSheets] row added or removed";
}

export class UpdatingWholeColumn {
  public static readonly type = "[TimeSheets] updating whole column";
}

export class RemoveTimeSheet {
  public static readonly type = "[TimeSheets] remove timesheet";
  constructor(
    public timesheetId: string,
    public reason: string,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class RecoverTimeSheet {
  public static readonly type = "[TimeSheets] recover timesheet";
  constructor(
    public timesheetId: string,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class RemoveTimeSheetRow {
  public static readonly type = "[TimeSheets] remove row";
  constructor(
    public timesheetId: string,
    public rowIds: string[],
    public callback: (response) => void
  ) {}
}

export class CreateTimesheetRow {
  public static readonly type = "[TimeSheets] create timesheet row";
  constructor(public timesheetId: string, public row: TimeSheetRow, public callback: any) {}
}

export class UpdateTimesheetRows {
  public static readonly type = "[TimeSheets] update timesheet rows";
  constructor(
    public timeSheetId: string,
    public rows: TimeSheetRow[],
    public callback: (error) => void
  ) {}
}

export class GetTimesheetDetails {
  public static readonly type = "[TimeSheets] get details";
  constructor(
    public id: string
  ) {}
}

export class SetTimeSheetStatusApproved {
  public static readonly type = "[TimeSheets] set status approved";
  constructor(public timeSheetId: string, public callback: (error) => void) {}
}

export class SetTimeSheetStatusPendingApproval {
  public static readonly type = "[TimeSheets] set status PendingApproval";
  constructor(public timeSheetId: string, public callback: (error) => void) {}
}

export class SetTimeSheetStatusPendingReapproval {
  public static readonly type = "[TimeSheets] set status PendingReapproval";
  constructor(
    public timeSheetId: string,
    public reason: string,
    public callback: (error) => void
  ) {}
}

export class SetTimeSheetStatusUnderReview {
  public static readonly type = "[TimeSheets] set status UnderReview";
  constructor(public timeSheetId: string, public callback: (error) => void) {}
}

export class SetTimeSheetStatusRejected {
  public static readonly type = "[TimeSheets] set status rejected";
  constructor(
    public timeSheetId: string,
    public reason: string,
    public callback: (error) => void
  ) {}
}

export class SetTimeSheetRowStatusRejected {
  public static readonly type = "[TimeSheets] set time sheet row rejected";
  constructor(
    public timeSheetId: string,
    public updateModel: UpdateTimeSheetRowStatus,
    public callback: (error) => void
  ) {}
}

export class SetTimeSheetRowStatusDraft {
  public static readonly type = "[TimeSheets] set time sheet row draft";
  constructor(
    public timeSheetId: string,
    public updateModel: UpdateTimeSheetRowStatus,
    public callback: (error) => void
  ) {}
}

export class ResetTimeSheetRows {
  public static readonly type = "[TimeSheets] reset timesheet rows";
  constructor() {}
}

export class GetExportCsvStream {
  public static readonly type = "[TimeSheets] Get CSV";
  constructor() {}
}

export class LoadTimeSheetsGroupedByLaborOrder {
  public static readonly type = "[TimeSheets] load grouped by labor order";
  constructor(public skipCount: number, public maxResult: number, public filters?: any) {}
}

export class SetLaborOrderTimesheets {
  public static readonly type = "[TimeSheets] Set Labor Order";
  constructor(public item: LaborOrder) {}
}

export class GetLaborOrderWithTimeSheets {
  public static readonly type = "[TimeSheets] get labor order with timesheets";
  constructor(public orderId: string) {}
}

export class CheckTimeSheetError {
  public static readonly type = "[TimeSheets] check error";
  constructor(public timeSheetId: string) {}
}

export class AdjustTimeSheet {
  public static readonly type = "[TimeSheets] adjustment";
  constructor(public timeSheetId: string, public callback: (error) => void) {}
}

export class ResetTimeheetsState {
  public static readonly type = "[TimeSheets] reset timesheets state";
  constructor() {}
}

export class DuplicateTimeSheet {
  public static readonly type = "[TimeSheets] duplicate";
  constructor(public timeSheetId: string, public callback: (error) => void) {}
}

export class SetTimeSheetListToStatusPendingApproval {
  public static readonly type = "[TimeSheets] set status timesheet list to PendingApproval";
  constructor(
    public orderId: string,
    public timeSheetIds: string[],
    public callback: (error) => void
  ) {}
}

export class SetTimeSheetListToStatusUnderReview {
  public static readonly type = "[TimeSheets] set status timesheet list to UnderReview";
  constructor(
    public orderId: string,
    public timeSheetIds: string[],
    public callback: (error) => void
  ) {}
}

export class SetTimeSheetListToStatusApproved {
  public static readonly type = "[TimeSheets] set status timesheet list to Approved";
  constructor(
    public orderId: string,
    public timeSheetIds: string[],
    public callback: (error) => void
  ) {}
}

export class ClearErrorUpdating {
  public static readonly type = "[TimeSheets] clear error updating";
  constructor() {}
}

export class ClearRowAdded {
  public static readonly type = "[TimeSheets] clear row added";
  constructor() {}
}

export class UpdateFilterByOrder {
  public static readonly type = "[Timekeeping By Orders] update filter";
  constructor( public value: any ) {}
}

export class ResetFilterByOrder {
  public static readonly type = "[Timekeeping By Orders] reset filter";
  constructor() {}
}

export class UpdateFilterByTimesheet{
  public static readonly type = "[Timekeeping Timesheet] update filter";
  constructor( public value: any ) {}
}

export class OfferAssociateTimesheetToOrder {
  public static readonly type = "[Timekeeping Timesheet] offer associate timesheet to order";
  constructor(
    public timesheetId: string,
    public laborOrder: LaborOrder,
    public fromList: boolean
  ) {}
}

export class AssociateTimesheetToOrder {
  public static readonly type = "[Timekeeping Timesheet] associate timesheet to order";
  constructor(
    public timesheetId: string,
    public laborOrderId: string,
    public fromList: boolean,
    public callbackSuccess: (response) => void,
    public callbackError: (error) => void
  ) {}
}

export class UpdateCurrentGang {
  public static readonly type = "[Timekeeping Timesheet] update current gang";
  constructor(
    public gang: string,
    public timesheetId: string
  ) {}
}

export class LoadTimekeepers {
  public static readonly type = "[Timekeeping Timesheet] load timekeepers";
}

export class SetTimesheetTabs {
  public static readonly type = "[Timekeeping Timesheet] set timesheet tabs";
  constructor(
    public currentTimesheetId: string
  ) {}
}